// Note: Requires flexslider 2 buddy.
jQuery(document).ready(function($) {
	$('.testimonial-wrapper .flexslider').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: true,
		directionNav: false,
	});

}); /* end of as page load scripts */